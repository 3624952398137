.editor-access {
  margin-left: 10px;
  color: #004643;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 3px;
}

.no-access {
  margin-left: 10px;
  color: #004643;
  font-weight: 700;
  margin-top: 0;
}