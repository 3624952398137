@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

:root {
  --primary-color: #2ca58d;
  --text-color: #004346;
  --background-color-1: #e1f9f3;
  --background-color-2: #e0e1e2;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

* {
  font-family: "Nunito", sans-serif;
}

.MuiBox-root {
  border-radius: 8px;
  padding: 30px;
  background-color: white;
}

.section-title {
  color: var(--primary-color);
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
}

.section-subtitle {
  font-size: 18px;
  color: var(--text-color);
  font-weight: 700;
  text-transform: uppercase;
}
.standard-btn {
  background-color: var(--primary-color);
  color: white;
  border-radius: 20px;
  padding: 12px 40px;
  text-transform: uppercase;
  border: none;
  font-weight: 600;
  cursor: pointer;
  letter-spacing: 0.5px;
  width: max-content;
  font-size: 1rem;
  margin: auto;
  width: 100%;
}
.standard-btn:hover {
  color: white;
  background-color: #248a75fa;
  transform: translateY(-3px);
  box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.5);
}

.standard-btn:focus {
  color: white;
  background-color: #248a75fa;
  box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.5);
  transform: translateY(0);
}

.section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  border-radius: 1.5rem;
  padding: 1.8rem 2.5rem;
}

input[type="date"][value=""]::-webkit-datetime-edit-day-field,
input[type="date"][value=""]::-webkit-datetime-edit-month-field,
input[type="date"][value=""]::-webkit-datetime-edit-year-field {
  opacity: 0.5;
}
