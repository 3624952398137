.color-selector {
  display: grid;
  grid-template-columns: 7rem 1fr;
  gap: 20px;
  align-items: center;
  padding-left: 10px;
}
.color-sample-container {
  display: flex;
  padding: 6px 8px;
  border: 1px solid #878787;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.color-sample {
  display: block;
  height: 20px;
  width: 20px;
  border-radius: 3px;
  border: 0.5px solid #878787;
}
.color-code {
  font-size: 14px;
}

.color-label {
  color: #004643;
  font-size: 18px;
  font-weight: 600;
}

.rcp-root {
  width: 17rem;
  position: absolute;
  z-index: 1000;
}
