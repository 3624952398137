.widget-card {
  border-radius: 20px;
  color: var(--primary-color);
  border: var(--primary-color) 2px solid;
  background-color: var(--background-color-1);
  font-weight: 700;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.widget-card:hover {
  color: white;
  background-color: #248a75fa;
  transform: translateY(-3px);
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.5);
}

.widget-card.active {
  color: white;
  background-color: var(--primary-color);
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.5);
  transform: translateY(0);
}
