.agenda-header-month {
  font-size: 1.5rem;
  color: var(--text-color);
  font-weight: 600;
  text-transform: capitalize;
}
.agenda-day-week-container {
  display: flex;
  gap: 10px;
  background-color: var(--background-color-2);
  padding: 6px;
  border-radius: 5px;
}
