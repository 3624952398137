.rbc-toolbar {
  justify-content: flex-start;
}

.rbc-btn-group {
  margin-right: auto;
  margin-left: 10px; /* Adjust the left margin as needed */
}

.rbc-event {
  background-color: #4fd1c5;
  padding: 0 !important;
}

.rbc-event.rbc-selected {
  background-color: #4fd1c5;
}
.rbc-selected {
  background-color: #4fd1c5;
}

.rbc-event:hover {
  background-color: #054643;
  z-index: 20 !important;
  min-width: 150px !important;
}

.rbc-event:active {
  background-color: #054643;
}

.rbc-event:focus {
  background-color: #054643;
}

.rbc-timeslot-group {
  min-height: 100px !important;
}

.rbc-header {
  border-bottom: none;
}

.rbc-button-link {
  font-size: 18px;
  font-weight: 800;
}

.rbc-event-label {
  display: none !important;
}

.rbc-header .rbc-button-link {
  font-weight: 400;
  text-transform: capitalize;
}

.rbc-today {
  background-color: initial;
}

.rbc-time-header-content .rbc-today .rbc-button-link {
  background-color: rgb(0, 134, 112);
  color: white;
  border-radius: 40px;
  padding: 4px 16px;
}

.rbc-time-header-content .rbc-row.rbc-row-resource {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rbc-time-header-content .rbc-allday-cell {
  display: none;
}

.rbc-time-header-content .rbc-row.rbc-time-header-cell .rbc-header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
