.duplicate-hours-container {
  display: grid;
  gap: 5px;
  align-self: start;
  width: 100%;
}

.duplicate-hours {
  text-decoration: underline;
  color: var(--primary-color);
  font-weight: 700;
  cursor: pointer;
}
