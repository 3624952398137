.website-page-container {
  display: flex;
}
.website-page-content {
  width: 100%;
  padding: 20px;
  display: grid;
  grid-row-gap: 20px;
  grid-template-rows: min-content 1fr;
  min-height: 100%;
}

.website-channels {
  display: flex;
  gap: 1rem;
}

.section {
  border: 1px solid #b2b2b2;
  padding: 1rem;
  border-radius: 12px;
}

.section-title {
  font-size: 20px;
}

.section-subtitle {
  font-size: 0.875rem;
  color: var(--text-color);
}
